import { tint } from '@elastic/eui';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Theme } from '@emotion/react';

export type BadgeVariants =
  | 'default'
  | 'hollow'
  | 'custom'
  | 'primary'
  | 'accent'
  | 'danger'
  | 'warning'
  | 'disabled'
  | 'success';

type BadgeSizes = 's' | 'm' | 'l';

// TODO: Does this need to be an export type?
// Only temporarily, because <Tag /> is referencing them
// Once Tag and Badge are condensed, they won't need to be
export type ColorPairProps = Record<BadgeVariants, { foreground: string; background: string }>;

interface BadgeProps {
  label: string;
  value?: string;
  icon?: React.ReactNode;
  variant?: BadgeVariants;
  size?: BadgeSizes;
}

const getColorPair = (variant: BadgeVariants, theme: Theme, foreground?: string, background?: string) => {
  const colorPairs: ColorPairProps = {
    default: { foreground: theme.colors.text, background: theme.colors.lightestShade },
    hollow: { foreground: theme.colors.text, background: theme.colors.emptyShade },
    custom: {
      foreground: foreground || theme.colors.text,
      background: background || theme.colors.lightestShade,
    },
    primary: {
      foreground: theme.categoryColorPairs[1].text,
      background: theme.categoryColorPairs[1].behindText,
    },
    accent: {
      foreground: theme.categoryColorPairs[3].text,
      background: theme.categoryColorPairs[3].behindText,
    },
    success: {
      foreground: theme.categoryColorPairs[0].text,
      background: theme.categoryColorPairs[0].behindText,
    },
    danger: {
      foreground: theme.categoryColorPairs[9].text,
      background: theme.categoryColorPairs[9].behindText,
    },
    warning: {
      foreground: theme.categoryColorPairs[5].text,
      background: theme.categoryColorPairs[5].behindText,
    },
    disabled: {
      foreground: theme.colors.disabledText,
      background: tint(theme.colors.disabled, 0.9),
    },
  };

  return colorPairs[variant];
};

const getBadgeFontSize = (size: BadgeSizes, theme: any) => {
  const badgeFontSizes: Record<BadgeSizes, string> = {
    s: theme.font.fontSizes.xs,
    m: theme.font.fontSizes.xs,
    l: theme.font.fontSizes.s,
  };

  return badgeFontSizes[size];
};

const getBadgePadding = (size: BadgeSizes, theme: any) => {
  const badgePadding: Record<BadgeSizes, string> = {
    s: `${theme.spacing.none} ${theme.spacing.sm}`,
    m: `${theme.spacing.xxs} ${theme.spacing.md}`,
    l: `${theme.spacing.sm} ${theme.spacing.lg}`,
  };

  return badgePadding[size];
};

const BadgeWrapper = styled.div<{
  variant: BadgeVariants;
  size: BadgeSizes;
  foreground?: string;
  background?: string;
}>(({ theme, variant, size, foreground, background }) => {
  const colorPair = getColorPair(variant, theme, foreground, background);

  return {
    display: 'inline-flex',
    whiteSpace: 'nowrap',
    borderRadius: (theme as any).spacing['3xl'],
    padding: getBadgePadding(size, theme),
    fontSize: getBadgeFontSize(size, theme),
    color: colorPair.foreground,
    backgroundColor: colorPair.background,
  };
});

const Badge: FC<BadgeProps> = ({ label, value, icon, variant = 'default', size = 's' }) => {
  return (
    <BadgeWrapper variant={variant} size={size}>
      <div>{icon}</div>
      <div>{label}</div>
      <div>{value}</div>
    </BadgeWrapper>
  );
};

export default Badge;
